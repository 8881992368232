@import "theme_v2/variables";
@import "~@ng-select/ng-select/themes/default.theme.css";

.form-input {
    margin-bottom: 24px;

    input-label {
        margin-bottom: $SpacingXS;
        display: block;
    }

    label {
        color: $GreyN800;
        font-weight: 500;
        display: block;
    }

    & > label {
        margin-bottom: 8px;
    }

    .hint-text {
        width: $DefaultFormFieldWidth;
        color: $GreyN500;
        margin-bottom: 8px;

        &.mobile-only {
            display: none;
        }
    }

    .input-container {
        width: $DefaultFormFieldWidth;
        position: relative;

        input,
        textarea,
        .ng-select-container {
            width: 100%;
        }

        &:has(textarea) {
            &:after {
                content: "";
                z-index: 1;
                position: absolute;
                bottom: 1px;
                left: 16px;
                right: 12px;
                pointer-events: none;
                background-color: $GreyN0;
                height: 28px;
            }
            character-counter {
                position: absolute;
                z-index: 10;
                bottom: 6px;
                left: $SpacingMD;
                color: $GreyN500;
            }
        }
        
        &:has(textarea:hover) {
            &:after {
                background-color: $GreyN20;
            }
        }
    }

    .validation-errors-container {
        display: none;
    }

    .field-validation-error {
        margin-top: 8px;
        color: $RedE800;
        margin-left: 20px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            height: 16px;
            width: 16px;
            left: -20px;
            background: $RedE800;
            mask-image: url("../assets/images/ico-error.svg");
            mask-position: unset;
            mask-repeat: no-repeat;
            mask-size: 16px;
        }
    }

    &.ng-touched > .validation-errors-container:not(:empty) {
        display: block;
    }

    &:focus-within {
        soldo-tooltip {
            .soldo-tooltip-dropdown {
                display: block;
            }
        }
    }

    @include media-breakpoint-mobile() {
        width: 100%;
        soldo-tooltip {
            display: none;
        }

        .hint-text {
            width: 100%;
            &.mobile-only {
                display: block;
            }
        }
        .input-container {
            width: 100%;
        }
    }
}

input[type="text"],
textarea {
    border: 1px solid $GreyN500;
    box-shadow: none;
    border-radius: 4px;
    background: transparent;
    font-size: inherit;
    
    &:hover {
        background-color: $GreyN20;
    }
}

input {
    border: 1px solid $GreyN500;
    color: $GreyN800;
    height: 36px;
    font-size: 14px;
    border-radius: $SpacingXXS;
    box-sizing: border-box;
    padding: $SpacingXS $SpacingMD;
    display: block;

    &:focus {
        outline: 2px solid $BlueI400;
    }

    &:not(:read-only):not(:disabled):hover {
        background-color: $GreyN20;
    }

    &:disabled {
        opacity: 0.32;
        background: $GreyN0;
        outline: none;
        cursor: auto;
        border: 1px solid $GreyN500;
    }

    &:not(:disabled):read-only {
        cursor: default;
        border: none;
        padding:unset;
        height: auto;

        &:focus,
        &:focus-visible {
            outline: none;
        }

        &:hover {
            background: transparent;
        }
    }

    &.ng-touched.ng-invalid:not(:read-only):not(:disabled):not(:focus) {
        border-color: $RedE800;
    }

    &::-webkit-input-placeholder {
        color: $GreyN500;
    }

    &[type="radio"] {
        opacity: 0;
        height: 0;
        margin-bottom: $SpacingS;

        &:checked + label:before {
            color: $GreyN900;
            content: "radio_button_checked";
        }

        &:disabled + label {
            cursor: auto;
            &::before {
                opacity: 0.32;
            }
        }

        &:not(:disabled):hover + label::before {
            background-color: $GreyN50;
            border-radius: 15px;
            outline: 4px solid $GreyN50;
        }

        &:focus,
        &:focus-visible {
            & + label:before {
                outline: 2px solid $BlueI400;
                border-radius: 20px;
            }
        }
        & + label {
            display: flex;
            &::before {
                font-size: 20px;
                font-family: "Material Icons", sans-serif;
                font-feature-settings: "liga";
                color: $GreyN800;
                content: "radio_button_unchecked";
                margin-right: $SpacingXS;
                vertical-align: middle;
                height: fit-content;
                cursor: pointer;
            }
        }
    }

    &[type="checkbox"] {
        opacity: 0;
        height: 0;
        margin: 0;
        & + label {
            display: inline-flex;
            outline: none;
            &:before {
                content: "";
                width: 20px;
                height: 20px;
                min-width: 20px;
                background-image: url("../assets/images/ico-checkbox-unchecked.svg");
                background-size: cover;
                margin-right: $SpacingXS;
                cursor: pointer;
            }

            &:hover::before {
                background-color: $GreyN50;
                border-radius: 10px;
                outline: 4px solid $GreyN50;
            }
        }
        &:disabled + label:hover::before {
            background-color: transparent;
            outline: transparent;
        }

        &:focus-visible,
        &:focus {
            & + label:before {
                outline: 2px solid $BlueI400;
                border-radius: 5px;
            }
        }

        &:checked {
            & + label {
                &:before {
                    height: 20px;
                    width: 20px;
                    background-image: url("../assets/images/ico-checkbox-checked.svg");
                    background-size: cover;
                }
            }
        }

        &:disabled {
            & + label {
                cursor: auto;
                &:before {
                    opacity: 0.32;
                }
            }
            &:hover + label::before {
                background-color: transparent;
            }
        }
    }
}

.checkbox .form-input {
    margin-bottom: $SpacingMD;
}

textarea {
    font-family: system-ui, sans-serif;
    border: 1px solid $GreyN500;
    color: $GreyN800;
    min-height: 103px;
    resize: vertical;
    border-radius: 4px;
    box-sizing: border-box;
    padding: $SpacingXS $SpacingMD;
    overflow: auto;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: $GreyN80 transparent;
    display: block;
    width: 100%;

    &:not(:disabled):not(:read-only):hover {
        background-color: $GreyN20;
    }

    &:focus,
    &:focus-visible {
        outline: 2px solid $BlueI400;
    }

    &.ng-invalid.ng-touched {
        border-color: $RedE800;
    }

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    &:read-only {
        cursor: default;
        background: $GreyN0;
        opacity: 0.32;

        &:focus,
        &:focus-visible {
            outline: none;
            border: 1px solid $GreyN500;
        }

        &:hover {
            background: transparent;
        }
    }
}

.conditional-subfield-container {
    position: relative;
    padding-left: 26px;
    margin-bottom: 8px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 2px $GreyN80 solid;
        margin-left: 16px;
    }

    & > :last-child {
        margin-bottom: 0px;
    }
}

fieldset {
    border: none;
    border-inline: 0;
    padding-inline: 0;
    padding-block: 0;
    & legend {
        display: none;
    }
}

.ng-select {
    &.ng-select-focused {
        .ng-select-container {
            outline: 2px solid $BlueI400;
        }
    }

    .ng-select-container .ng-value-container {
        & .ng-input > input {
            cursor: text;
        }

        padding: $SpacingXS $SpacingMD;
    }

    &.ng-select-single .ng-select-container {
        border: 1px solid $GreyN500;

        &:hover {
            box-shadow: none;
            background-color: $GreyN20;
        }
        .ng-value-container .ng-input {
            padding: 0 16px;
            & > input {
                margin-top: -5px;
            }
        }
    }

    .ng-dropdown-panel {
        position: absolute;
        box-shadow:
            0px 1px 2px rgba(0, 0, 0, 0.04),
            0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        overflow: hidden;
        border: none;

        &.ng-select-top {
            bottom: 40px;
        }
        &.ng-select-bottom {
            top: 40px;
        }

        .ng-dropdown-panel-items {
            &.scroll-host::-webkit-scrollbar {
                display: none;
            }

            & .ng-option {
                padding: $SpacingXS $SpacingMD;

                &.ng-option-selected,
                &.ng-option-marked,
                &.ng-option-selected.ng-option-marked {
                    // @CONFIRM --> also in phone-input?
                    background-color: $GreyN20;
                    color: $GreyN800;
                }
            }
        }
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $GreyN500;
        box-shadow: unset;
    }

    &.ng-invalid.ng-touched {
        .ng-control,
        .ng-select-container {
            border-color: $RedE800;
        }
    }
    &.ng-select-disabled {
        opacity: 0.32;
    }

    span.ng-arrow-wrapper,
    &.ng-select-opened span.ng-arrow-wrapper {
        right: 0;
        cursor: pointer;
        position: absolute;
        width: 16px;
        height: 16px;
        margin-right: $SpacingMD;
        & > span.ng-arrow {
            top: 0px;
            border-style: none;
            border-width: 0;
            background-image: url("../assets/images/ico-arrow-down-centered.svg");
            background-repeat: no-repeat;
            background-size: $SpacingMD $SpacingMD;
            width: 16px;
            height: 16px;
        }
    }

    &.ng-select-opened {
        &.ng-select-bottom,
        &.ng-select-top {
            & > .ng-select-container {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        .ng-arrow-wrapper .ng-arrow  {
            transform: rotate(180deg);
        }
    }
}

.chips-radio {
    position: relative;

    .radio-item {
        margin-bottom: $SpacingMD;

        input[type="radio"] + label {
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 100px;
            border: 1px solid $GreyN50;
            background-color: $GreyN0;
            padding: 10px 16px;
            width: fit-content;
            gap: 4px;
            margin-bottom: 8px;

            &::before {
                display: none;
                position: absolute;
                content: "";
            }

            &:hover {
                background-color: $GreyN50;
                &::before {
                    background-color: unset;
                }
            }
        }

        input[type="radio"] {
            margin: 0;
            &:checked {
                & + label {
                    color: $GreyN0;
                    background: $GreenS400;
                    border-color: $GreenS400;
                }
            }
            &:disabled {
                & + label {
                    cursor: auto;
                    opacity: 0.32;
                    &:hover {
                        border-color: $GreyN80;
                        background-color: transparent;
                    }
                    &::before {
                        opacity: 0.32;
                    }
                }
                &:checked {
                    & + label {
                        border-color: transparent;
                        background: $GreenS400;
                    }
                }
                &:hover + label::before {
                    background-color: transparent;
                }
            }

            &:focus,
            &:focus-visible {
                & + label {
                    outline: 2px solid $BlueI400;
                    border-radius: 20px;
                }
            }
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    border: 1px solid $GreyN80;
    padding: $SpacingMD;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;

    &:hover {
        border-color: $GreyN500;
    }

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}

[type="radio"]:checked + .card {
    color: $GreyN0;
    background: $GreenS400;
    border-color: $GreenS400;
}

.description_container {
    display: flex;
    margin-top: -11px;

    .spacer {
        border-left: 1px solid $GreyN50;
        flex-grow: 0;
        flex-shrink: 0;
        width: 12px;
        margin-left: 12px;
        height: 82px;
    }

    soldo-field-layout {
        flex: auto;
    }
}

// TEMPORARY CUSTOM ELEMENTS

.autocomplete-field {
    position: relative;

    input {
        padding-left: 3rem;
        background-color: $GreyN50;
        border-color: $GreyN50;
        &:not(:read-only):hover {
            background-color: $GreyN20;
            border-color: $GreyN20;
        }

        &:read-only:hover {
            background-color: $GreyN50;
            border-color: $GreyN50;
        }

        &:disabled + typeface-icon span {
            opacity: 0.32;
        }
    }

    .ng-select {
        .ng-select-container {
            background-color: $GreyN50;
            border-color: $GreyN50;
            .ng-value-container {
                padding-left: 3rem;
                .ng-input {
                    padding-left: 3rem;
                }
            }
        }

        &.ng-select-focused > .ng-select-container {
            outline: 2px solid $BlueI400;
            border-color: $GreyN50;
        }

        .ng-arrow-wrapper {
            display: none;
        }
    }

    typeface-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        z-index: 1002;

        span {
            color: $GreyN500;
            font-size: 24px;
        }
    }
}

div:focus-visible {
    outline: none;
}

.form-page-CTA-row {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    margin-bottom: 24px;
}
