$BodyRegularFontSize:14px;
$NormalFontWeight: 400;
$MediumFontWeight: 500;

$BaseLineHeight: 20px;

$DefaultMainContentWidth: 454px;

//
// COLORS
//
// ---------------------- BRAND (ACCENT)
$OrangeA700:#951400;
$OrangeA500:#E03B00;
$OrangeA400:#FF7D0A;
$OrangeA50:#FCD78B;
$OrangeA20:#FFF0D2;

// ---------------------- NEUTRALS (Grey)
$GreyN900:#0B1412;
$GreyN800:#242B30;
$GreyN700:#383D44;
$GreyN500:#5E626B;
$GreyN400:#6F7179;
$GreyN200:#93959C;
$GreyN100:#B6B7BC;
$GreyN80:#D2D2D2;
$GreyN50:#E8E8EA;
$GreyN20:#F6F6F6;
$GreyN0:#FFFFFF;

// ----------------------- SEMANTIC - SUCCESS (Green)
$GreenS600:#115549;
$GreenS400:#208164;
$GreenS200:#26BC93;
$GreenS80:#99DECF;
$GreenS20:#DBF7F0;

// ----------------------- SEMANTIC - Informative (Blue)
$BlueI500:#015A8F;
$BlueI400:#047CC3;
$BlueI200:#69AAF5;
$BlueI100:#98B4F1;
$BlueI80:#C4CBF3;
$BlueI20:#EBECFA;

// ---------------------- SEMANTIC - WARNING (Yellow)
$YellowW700:#5B3C09;
$YellowW300:#BA8B18;
$YellowW80:#FCC923;
$YellowW50: #F9EC81;
$YellowW20:#FDFDE8;

// ---------------------- SEMANTIC - ERROR (Red)
$RedE800:#B71632;
$RedE700:#FF2828;
$RedE400:#FF6F79;
$RedE100:#FFB3C1;
$RedE20:#FFEBF0;

// ---------------------- SPACING
$SpacingXXS:4px;
$SpacingXS:8px;
$SpacingS:12px;
$SpacingMD:16px;
$SpacingLG:24px;
$SpacingXL:32px;
$SpacingXXL:40px;
$SpacingXXXL:48px;

// ----------------- MODAL WIDTH
$ModalWidthS: 360px;
$ModalWidthM: 552px;
$ModalWidthL: 744px;
$ModalWidthXL: 936px;

// ---------------------- LAYOUT
$DefaultFormFieldWidth: 315px;

// MIXINS
@mixin media-breakpoint-mobile(){
    @media (max-width: "1024px") {
        @content;
    }
}
