@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}

button {
    cursor: pointer;
    font-size: $BodyRegularFontSize;
    padding: 0 $SpacingLG;
    border-radius: $SpacingXXS;
    font-weight: $NormalFontWeight;
    color: $GreyN700;
    background-color: $GreyN0;
    border: 1px solid $GreyN800;
    width: fit-content;
    position: relative;
    line-height: 34px;
    white-space: nowrap;

    &:disabled {
        cursor: not-allowed;
        opacity: 32%;

        &.loading, &.loading:hover {
            color: transparent;

            &:before {
                content: " ";
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                background: $GreyN400;
                mask-image: url("../assets/images/loading.svg");
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: 22px;
                animation: loading 2s linear infinite;
            }
        }
    }

    &:hover {
        color: $GreyN900;
        border-color: $GreyN900;
    }

    &:focus {
        outline: 2px solid $BlueI400;
        background-color: $GreyN50;
    }

    &:has(typeface-icon) {
        gap: $SpacingXS;
        justify-content: center;
        display: flex;

        typeface-icon span {
            font-size: 20px;
        }
    }
}

button.primary {
    color: $GreyN0;
    background-color: $GreyN800;

    &:disabled {
        &.loading {
            &:before {
                background-color: $GreyN50;
            }
        }
    }

    &:hover {
        background: $GreyN900;
        color: $GreyN0;
    }
}

button.link {
    border: none;
    font-weight: $MediumFontWeight;
    align-items: center;
    text-decoration: underline;

    &:hover {
        color: $GreyN900;
    }

    span {
        text-decoration: underline;
    }
    typeface-icon span {
        text-decoration: none;
    }

    &.no-padding {
        padding: 0;
        line-height: 20px;

        &.caption {
            line-height: 16px;
        }

        &:not(.loading) {
            height: max-content;
        }

        &.loading {
            width: 30px;
        }
    }

    &:focus {
        background-color: $GreyN0;
        outline: 2px solid $BlueI400;
    }
}

button.icon {
    border: none;
    padding: 0;
    font-size: 20px;
    line-height: 20px;
}

button.destructive {
    border-color: $RedE700;
    color: $RedE800;

    &.loading {
        &:before {
            background-color: $RedE400;
        }
    }

    &:hover {
        border-color: $RedE400;
        color: $RedE400;
    }

    &:focus {
        background-color: $GreyN0;
        border-color: $BlueI400;
    }
}

button.primary.destructive {
    background: $RedE800;
    border-color: $RedE800;
    color: $GreyN0;

    &.loading {
        color: transparent;
        border-color: $GreyN0;
        &:before {
            background-color: $RedE20;
        }
    }

    &:hover {
        background: $RedE400;
        border-color: $RedE400;
        color: $GreyN0;
    }

    &:focus {
        border-color: $BlueI400;
    }
}
