@import "./variables";

body {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: system-ui, sans-serif;
    font-size: $BodyRegularFontSize;
    line-height: 20px;
}

// Header light
h1 {
    font-size: 40px;
    line-height: 52px;
    font-weight: 300;
}

// Header bold
h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

// Title
h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
}

// Subtitle 1
h4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
}

// Subtitle 2
h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

a {
    color: $GreyN800;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
        color: $GreyN900;
    }
}

.caption,
.caption span,
.caption a {
    font-size: 12px;
    line-height: 16px;
}

// *****************
// typography
// *****************

.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
