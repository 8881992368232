@use "sass:math";

@import "theme_v2/variables";
@import "theme_v2/type";
@import "theme_v2/form";
@import "theme_v2/buttons";

soldo-app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: $GreyN0;
}

body {
    margin: 0;
    &.modal-is-open {
        overflow: hidden;
    }

    &.route-static-identity-verification {
        app-header,
        div.version.shown {
            display: none;
        }
    }
}

.content {
    margin: 40px auto;
    width: $DefaultMainContentWidth; // @CONFIRM

    @include media-breakpoint-mobile() {
        width: auto;
        margin: 40px 20px; // @CONFIRM
    }
}

div.layout-section {
    margin: 32px 0;
}

.callout {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $GreyN800;

    &.success {
        border: none;
        background-color: $GreenS20;
        .callout-content:before {
            background-image: url("./assets/images/callout-icon-success.svg");
        }
    }
    &.error {
        border: none;
        background-color: $RedE20;
        .callout-content:before {
            background-image: url("./assets/images/callout-icon-error.svg");
        }
    }
    &.informative {
        border: none;
        background-color: $BlueI20;
        .callout-content:before {
            background-image: url("./assets/images/callout-icon-informative.svg");
        }
    }
    &.warning {
        border: none;
        background-color: $YellowW20;
        .callout-content:before {
            background-image: url("./assets/images/callout-icon-warning.svg");
        }
    }
    &.tip {
        border: none;
        background-color: $GreyN50;
        .callout-content:before {
            background-image: url("./assets/images/callout-icon-tip.svg");
        }
    }

    .callout-content{
        padding-left: 32px;
        position: relative;
        &:before {
            background-image: url("./assets/images/callout-icon-default.svg");
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 24px;
            height: 24px;
        }

        p {
            margin-top: 0px;
        }
    }
}

.tip-box {
    background-color: $GreyN20;
    padding: 16px;
    border-radius: 4px;
}

.loading-screen {
    $thisWidth: 36px;
    content: "";
    position: absolute;
    width: $thisWidth;
    height: $thisWidth;
    left: calc(50% - #{$thisWidth * 0.5});
    top: calc(50% - #{$thisWidth * 0.5});
    background-image: url("./assets/images/loading/loading-dots.png");
    background-position: center;
    background-size: 100%;
    animation: loadingScreenAnimation 1s infinite step-end reverse;
}

//animation loading 3dots
@keyframes loadingScreenAnimation {
    $pos2: 36px;
    @for $i from 0 through 12 {
        #{$i * 8.33333333333%} {
            background-position: 0px $pos2 * $i;
        }
    }
}

#obsolete-browser {
    text-align: center;
    position: absolute;
    top: 30%;
    width: 100%;
    animation: obsoleteBrowserDisplay 0s 7s forwards;
    opacity: 0;
}

@keyframes obsoleteBrowserDisplay {
    to {
        opacity: 1;
    }
}
